<script>
import Match from './Match'
import EmptyList from '@/app/components/general/EmptyList.vue'
import PlaceholderLoadingStageGroups from '@/app/components/general/placeholders/LoadingStageGroups.vue'

import StageApiService from '../../../services/domains/Stage'

import bus from '@utils/bus'
import helpers from '@mixins/helper'
import {orderBy} from 'lodash'
import DrawBracketsGenerator from "./DrawBrackets/Index.vue";

const stageService = StageApiService.build({})

export default {
  components: {DrawBracketsGenerator, EmptyList, Match, PlaceholderLoadingStageGroups},
  mixins: [helpers],
  props: ['stage'],
  data() {
    return {
      isLoading: true,
      stageId: null,
      tenantId: null,
      slugName: null,
      showDrawBrackets: true,
      list: [],
      selectedPlayersClassIndex: 0,
      selectedPlayersClass: null,
    }
  },
  computed: {
    stageSingle() {
      return this.stage.single
    },
    websocketKey() {
      return `matchResultChange`
    },
    websocketChannelName() {
      return `${this.websocketKey}.${this.tenantId}.${this.stageId}`
    },
    websocketChannelListen() {
      return `.${this.websocketKey}`
    },
    placeholderTitle() {
      if (this.isLoading) {
        return this.$t('app.domains.stage.components.groups.groupsLoadingTitle')
      }
      return this.$t('app.domains.stage.components.groups.groupsNotAvailableTitle')
    }
  },
  watch: {
    '$route.params.player_class': {
      deep: true,
      immediate: true,
      handler: function (value) {
        const playerClass = this.list.find(item => this.slugify(item.name_with_sex) === value)
        if (playerClass) {
          this.selectTab(playerClass)
        }
      }
    },
    selectedPlayersClassIndex(value) {
      const selectedPlayerClass = this.list.find((item, index) => index === value)
      this.selectedPlayerClass = selectedPlayerClass
      if (selectedPlayerClass) {
        this.routeSlugChange(selectedPlayerClass.name_with_sex)
      }
    }
  },
  mounted() {
    bus.$emit('show-loader')
    const [slug, stage] = this.$route.params.slug.split('--')
    const [tenant, id] = stage.split('-')
    this.stageId = id
    this.tenantId = tenant
    this.slugName = slug
    this.loadPairs(this.stageId, this.tenantId)
    this.startWebsocket()
  },
  methods: {
    startWebsocket() {
      window.Echo.channel(this.websocketChannelName)
          .listen(this.websocketChannelListen, ({matches}) => {
            if (matches && matches.length) {
              const playerClassIndex = this.list.findIndex(item => item.id === this.selectedPlayersClass.id)
              const playerClass = this.list[playerClassIndex] || null
              matches.forEach(match => {
                let matchesArray = []
                if (playerClass?.type === 'eliminatory') {
                  matchesArray = this.list[playerClassIndex]?.rounds?.find(item => item.round === match.round)?.matches || []
                } else {
                  matchesArray = this.list[playerClassIndex]?.matches || []
                }
                const matchIndex = matchesArray.findIndex(item => {
                  if (match.id) {
                    return item.id === match.id
                  } else {
                    return item.court_id === match.court_id
                        && item.date === match.date
                        && item.hour === match.hour
                  }
                })
                if (matchIndex > -1) {
                  match.metadata.loading = true
                  matchesArray.splice(matchIndex, 1, match)
                  setTimeout(() => {
                    match.metadata.loading = false
                    matchesArray.splice(matchIndex, 1, match)
                  }, 1500)
                }
              })
            }
          })
    },
    loadPairs(id, tenant) {
      this.isLoading = true
      this.list.splice(0)
      const data = {tenant_id: tenant}
      stageService.groups(id, data)
          .then(response => {
            this.list = [...response]
            if (response.length) {
              if (this.$route.params.player_class) {
                const playerClass = this.list.find(item => this.slugify(item.name_with_sex) === this.$route.params.player_class)
                if (playerClass) {
                  this.selectTab(playerClass)
                }
              } else {
                this.selectTab(response[0])
              }
            }
            setTimeout(() => {
              bus.$emit('hide-loader')
              this.isLoading = false
            }, 200)
          })
          .catch(err => {
            console.log(err)
            this.isLoading = false
            bus.$emit('hide-loader')
          })
    },
    selectTab(playerClass) {
      this.selectedPlayersClass = playerClass
      this.selectedPlayersClassIndex = this.list.findIndex(item => item.id === playerClass.id)
    },
    routeSlugChange(playerClassName) {
      if (this.$route.params.player_class !== this.slugify(playerClassName)) {
        this.$router.push({
          name: 'stage.show.groups',
          params: {...this.$route.params.slug, player_class: this.slugify(playerClassName)}
        })
      }
    },
    getMatchesOfGroup(group) {
      const playerClassIndex = this.list.findIndex(item => item.id === this.selectedPlayersClass.id)
      return orderBy(this.list[playerClassIndex] && this.list[playerClassIndex].matches
          ? this.list[playerClassIndex].matches.filter(item => item.group === group && item.round === 99)
          : [], ['datetime'], ['asc'])
    },
    getEliminatoryMatches() {
      const playerClassIndex = this.list.findIndex(item => item.id === this.selectedPlayersClass.id)
      return this.list[playerClassIndex] && this.list[playerClassIndex].matches ? this.list[playerClassIndex].matches.filter(item => item.round !== 99) : []
    },
    showDrawBracketsMatches(playerClassRounds) {
      return playerClassRounds.reduce((matches, round) => matches = [...matches, ...round.matches], [])
    }
  }
}
</script>

<template>
  <div>
    <div v-if="!isLoading && list.length > 0">
      <v-tabs background-color="grey lighten-3"
              v-model="selectedPlayersClassIndex"
              slider-color="primary"
              class="players-class-tabs"
              centered
              show-arrows>
        <v-tab v-for="(item, index) in list"
               :key="'tab_' + index">
          <span class="caption">{{ item.name_with_sex }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedPlayersClassIndex" :touchless="selectedPlayersClass.type === 'eliminatory'">
        <v-tab-item v-for="(item, index) in list"
                    :key="'tab_' + index">
          <v-container grid-list-lg text-xs-center class="groups-container pl-2 pr-2">
            <v-layout row wrap>
              <template v-if="item.type === 'groups'">
                <template v-for="(group, index) in item.groups">
                  <v-flex xs12 sm6 lg4 :key="`${group.group}-${index}`">
                    <v-card class="stage-groups-card">
                      <v-card-title class="subheading light-green darken-3 white--text">
                        <span class="mr-2">
                          {{ $t('app.domains.stage.components.groups.groupText') }}
                        </span>
                        <strong>{{ group.group }}</strong>
                      </v-card-title>
                      <table class="stage-groups-table" v-if="group.pairs.length">
                        <thead>
                        <tr>
                          <th>
                          <span v-if="!stageSingle">{{
                              $t('app.domains.stage.components.groups.table.pairName')
                            }}</span>
                            <span v-else>{{ $t('app.domains.stage.components.groups.table.singleName') }}</span>
                          </th>
                          <th>{{ $t('app.domains.stage.components.groups.table.wins') }}</th>
                          <th>{{ $t('app.domains.stage.components.groups.table.setsBalance') }}</th>
                          <th>{{ $t('app.domains.stage.components.groups.table.games') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(pair) in group.pairs" :key="pair.id">
                          <td class="names text-capitalize">
                            <div>{{ pair.player1.toLowerCase() }}</div>
                            <div v-if="!stageSingle"> / {{ pair.player2.toLowerCase() }}</div>
                          </td>
                          <td class="text-center">
                          <span>
                            {{ pair.wins_classification !== undefined ? pair.wins_classification : pair.wins }}
                          </span>
                          </td>
                          <td class="text-center">
                          <span>
                            {{
                              pair.sets_balance_classification !== undefined ? pair.sets_balance_classification : pair.sets_balance
                            }}
                          </span>
                          </td>
                          <td class="text-center">
                          <span>
                            {{ pair.games_classification !== undefined ? pair.games_classification : pair.games }}
                          </span>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </v-card>
                    <v-card class="stage-groups-card -small" style="margin-top: 0"
                            v-if="getMatchesOfGroup(group.group).length > 0">
                      <v-card-title class="subheading grey lighten-2">
                        <span class="mr-2">{{ $t('app.domains.stage.components.groups.matchesText') }}</span>
                      </v-card-title>
                      <div class="matches-list">
                        <template v-for="(match, matchIndex) in getMatchesOfGroup(group.group)">
                          <match :match="match"
                                 :key="match.id"
                                 show-court
                                 show-date
                                 :show-round="false"/>
                          <v-divider :key="`${match.id}-${matchIndex}`"></v-divider>
                        </template>
                      </div>
                    </v-card>
                  </v-flex>
                </template>
                <v-flex xs12 v-if="getEliminatoryMatches().length > 0">
                  <v-card class="stage-groups-card -small">
                    <v-card-title class="subheading grey lighten-2">
                      <span class="mr-2">{{ $t('app.domains.stage.components.groups.eliminatoryMatchesText') }}</span>
                    </v-card-title>
                    <div class="matches-list">
                      <template v-for="(match, matchIndex) in getEliminatoryMatches()">
                        <match :match="match"
                               :key="match.id"
                               show-court
                               show-date
                               show-round/>
                        <v-divider :key="`${match.id}-${matchIndex}`"></v-divider>
                      </template>
                    </div>
                  </v-card>
                </v-flex>
              </template>
              <template v-else>
                <v-flex xs12>
                  <div class="text-center mb-3">
                    <v-btn small :class="['mr-1', {'white--text': showDrawBrackets}]"
                           :color="showDrawBrackets ? 'secondary' : 'default'"
                           @click="showDrawBrackets = true">
                      <i class="fas fa-sitemap"></i>
                      <span class="ml-2">Draw</span>
                    </v-btn>
                    <v-btn small :class="{'white--text': !showDrawBrackets}"
                           :color="!showDrawBrackets ? 'primary' : 'default'"
                           @click="showDrawBrackets = false">
                      <i class="fas fa-list"></i>
                      <span class="ml-2">Rounds</span>
                    </v-btn>
                  </div>
                  <template v-if="showDrawBrackets">
                    <draw-brackets-generator
                        :matches="showDrawBracketsMatches(item.rounds)"
                        :rounds="item.rounds"/>
                  </template>
                  <template v-else v-for="(round, roundIndex) in item.rounds">
                    <v-card class="stage-groups-card mb-3" :key="roundIndex" v-if="round.matches.length">
                      <v-card-title class="subheading light-green darken-3 white--text">
                        {{ round.round_name }}
                      </v-card-title>
                      <div class="matches-list">
                        <template v-for="(match, matchIndex) in round.matches">
                          <match :match="match"
                                 :key="match.id"
                                 show-court
                                 show-date
                                 show-round/>
                          <v-divider :key="`${match.id}-${matchIndex}`"></v-divider>
                        </template>
                      </div>
                    </v-card>
                  </template>
                </v-flex>
              </template>
            </v-layout>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <template v-if="isLoading || list.length === 0">
      <empty-list :title="placeholderTitle" message="" v-if="!isLoading && list.length === 0"/>
      <placeholder-loading-stage-groups v-else/>
    </template>
  </div>
</template>
